import { render, staticRenderFns } from "./ChannelList.vue?vue&type=template&id=b34634ea&"
import script from "@/assets/js/channel.js?vue&type=script&lang=js&"
export * from "@/assets/js/channel.js?vue&type=script&lang=js&"
import style0 from "@/assets/css/channel.css?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VContainer,VDataTable,VFlex,VIcon,VRow,VTooltip})
