export default {
	name: 'ChannelList',
	data: () => ({
		selectedvendlist: '',
		selectedlabel: '',
		ordertable: {
			sortBy: ['ext_po'],
			itemsPerPage: -1
		},
		headers: [
			{ text: 'Name', value: 'name' },
			{ text: 'State', value: 'state' },
			{ text: 'Region', value: 'region' },
		],
		recsdisplayed: 0,
		expanded: [],
		whatexpand: '',
	}),
	mounted() {
	},
	computed: {
		channellist: { get() { return this.$store.getters["Channel/getChannelList"]; }, },
		refreshing: { get() { return this.$store.getters["Wolf/getRefreshing"]; }, },
	},
	methods: {
		setrowclass(item) {
			return 'wolf-list ' + item.state
		},
		refresh() {
			this.$store.dispatch('Channel/fetchChannelList', null, { root: true })
		},
		savetablestate() {
			/* --- have to next tick this because needs to happen after mounted above --- */
			this.$nextTick(() => {
				localStorage.setItem('ordertable', JSON.stringify(this.ordertable))
			})
		},
		gettotalitems(e) {
			this.recsdisplayed = e.itemsLength
		},
		showMore(item, what) {
			this.whatexpand = ''

			if (this.expanded.length && this.expanded[0].docid === item.docid) {
				this.expanded = []
			} else {
				this.expanded = []
				this.expanded.push(item)
			}

			switch (what) {
				case 'lastnote':
					this.whatexpand = 'lastnote'
					break
			}
		},
		filterit(what) {
			if (what === 'select-vendor' || what === 'showall') {
				this.selectedvendlist = ''
			}
		}
	},
	watch: {
		$route() {
			/* --- being lazy - should have most data on board and switch here --- */
			let fp = this.$route.fullPath,
				titleval = ''

			fp = fp.split('/')

			if (fp.length === 4) {
				this.$store.dispatch("Channel/fetchChannel", ({ id: fp[3], region: fp[2] }), { root: true })
					.then(() => {
						titleval = this.$store.getters["Channel/getChannel"].Name.toUpperCase()
						this.$store.commit('Wolf/SET_PAGENAME', titleval)
						document.title = titleval + " - JL" || "JL";
					})
			} else {
				this.$store.dispatch("Channel/fetchChannelList", null, { root: true })
					.then(() => {
						titleval = fp[2].toUpperCase()
						this.$store.commit('Wolf/SET_PAGENAME', titleval)
						document.title = titleval + " - JL" || "JL";
					})
			}
		}
	}
}