export default {
	name: 'ChannelInfo',
	data: () => ({
		askchangestate: false,
		askchangeurl: false,
		askpowercycle: false,
		selectedchannel: '',
		selecteddestination: '',
		selectedurl: '',
		changetourl: '',
		currentop: '',
		whichway: ''
	}),
	async mounted() {
	},
	computed: {
		channel: {
			get() { return this.$store.getters["Channel/getChannel"]; },
		},
		powercolor: {
			get() { return this.$store.getters["Channel/getPowerColor"]; },
		}
	},
	methods: {
		refresh() {
			this.$store.commit('Wolf/SET_REFRESHING', true, { root: true })
			this.$store.dispatch("Channel/fetchChannel", { id: this.channel.Id, region: this.channel.region }, { root: true })
				.then(() => {
					this.$store.commit('Wolf/SET_REFRESHING', false, { root: true })
				})
		},
		executeCommand(op) {
			switch (op) {
				case 'changeurl':
					for (let idx = 0; idx < this.channel.Destinations.length; idx++) {
						if (this.channel.Destinations[idx].Id === this.selecteddestination) {
							for (let idxu = 0; idxu < this.channel.Destinations[idx].Settings.length; idxu++) {
								if (this.channel.Destinations[idx].Settings[idxu].Url === this.selectedurl) {
									this.channel.Destinations[idx].Settings[idxu].Url = this.changetourl

								}
							}
						}
					}

					this.$store.dispatch("Channel/changeUrl", {
						id: this.channel.Id,
						destinations: this.channel.Destinations,
						region: this.channel.region
					}, { root: true })
						.then(() => {
							this.refresh()
						})
						.catch((err) => {
							console.log('CHANGE URL ERR')
							console.log(err)
						})

					this.askchangeurl = false
					break
				case 'cyclepower':
					this.$store.dispatch("Channel/cyclePower", {
						id: this.channel.Id,
						region: this.channel.region
					}, { root: true })
						.then(() => {
							this.refresh()
						})
						.catch((err) => {
							console.log('CHANGE URL ERR')
							console.log(err)
						})

					this.askpowercycle = false
					break
			}
		},
		editUrl(id, whichone) {
			this.selecteddestination = id
			this.selectedurl = whichone
			this.changetourl = whichone

			this.askchangeurl = true
		},
		powercycle() {
			if (this.channel.State === 'IDLE') {
				this.whichway = ' ON '
			} else {
				this.whichway = ' OFF '
			}

			this.askpowercycle = true
		}
	}
}