<template>
  <v-container fluid>
    <v-row v-if="!$route.params.channel" class="text-center">
      <keep-alive>
        <ChannelList />
      </keep-alive>
    </v-row>
    <v-row v-if="loadcomponent === 'regionlist'" class="text-center">
      <keep-alive>
        <ChannelList />
      </keep-alive>
    </v-row>
    <v-row v-if="loadcomponent === 'channelinfo'" class="text-center">
      <keep-alive>
        <ChannelInfo />
      </keep-alive>
    </v-row>
  </v-container>
</template>

<script>
import ChannelList from "@/components/ChannelList.vue";
import ChannelInfo from "@/components/ChannelInfo.vue";

export default {
  name: "Order",
  components: {
    ChannelList,
    ChannelInfo,
  },
  mounted() {
    this.$store.dispatch("Channel/fetchChannelList", null, { root: true });
  },
  computed: {
    loadcomponent: {
      get() {
        return this.$store.getters["Wolf/getComponent"];
      },
    },
  },
};
</script>