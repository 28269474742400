var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pl-5 pt-10",attrs:{"fluid":""}},[_c('v-row',[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.channellist,"item-class":_vm.setrowclass,"options":_vm.ordertable,"expanded":_vm.expanded,"loading":_vm.refreshing,"item-key":"docid","single-expand":"","multi-sort":"","dense":""},on:{"update:options":[function($event){_vm.ordertable=$event},_vm.savetablestate],"update:expanded":function($event){_vm.expanded=$event},"pagination":_vm.gettotalitems},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-flex',{staticClass:"pt-1",attrs:{"xs2":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"indigo"},on:{"click":_vm.refresh}},'v-icon',attrs,false),on),[_vm._v(" mdi-refresh ")])]}}])},[_c('span',[_vm._v("Refresh List")])])],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
            params: { name: 'ml', channel: [item.region, item.id] },
          }}},[_vm._v(_vm._s(item.name))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }